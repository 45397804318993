import React from 'react';
import { IconType } from 'react-icons';

interface SocialIconLinkProps {
  href: string;
  label: string;
  Icon: IconType;
  className?: string;
}

const SocialIconLink: React.FC<SocialIconLinkProps> = ({ href, label, Icon, className }) => (
  <a href={href} aria-label={label} className={`hover:text-yellow-400 ${className}`} target="_blank" rel="noopener noreferrer">
    <Icon size={24} />
  </a>
);

export default SocialIconLink;
