import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const Home = lazy(() => import('./pages/Home/Home'));
const Service = lazy(() => import('./pages/Service/Service'));
const About = lazy(() => import('./pages/About/About'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));

const AnimatedRoutes: React.FC = () => {
    const location = useLocation();

    return (
        <Suspense fallback={<LoadingSpinner/>}>
            <AnimatePresence mode="wait">
                <ScrollToTop key="scroll-to-top" />
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/service/:category" element={<Service/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </AnimatePresence>
        </Suspense>
    );
};

const App: React.FC = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Navbar/>
                <main className="flex-grow pt-12 md:pt-12 mt-4">
                    <AnimatedRoutes/>
                </main>
                <Footer/>
            </div>
        </Router>
    );
};

export default App;
